<template>
  <div>
    <div class="section hero wf-section">
      <div
          data-poster-url="https://uploads-ssl.webflow.com/62f4510e73201624361551ab/62f4967f04e067abd8d509cf_Iduam_Monitoreamos_Cada_latido_Español_Final (1)-poster-00001.jpg"
          data-video-urls="https://uploads-ssl.webflow.com/62f4510e73201624361551ab/62f4967f04e067abd8d509cf_Iduam_Monitoreamos_Cada_latido_Español_Final (1)-transcode.mp4,https://uploads-ssl.webflow.com/62f4510e73201624361551ab/62f4967f04e067abd8d509cf_Iduam_Monitoreamos_Cada_latido_Español_Final (1)-transcode.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          class="background-video w-background-video w-background-video-atom"
      >
        <video
            id="2c384245-6ee0-9d57-bb04-3436793b5cd2-video"
            autoplay
            loop
            style="
            background-image: url('https://uploads-ssl.webflow.com/62f4510e73201624361551ab/62f4967f04e067abd8d509cf_Iduam_Monitoreamos_Cada_latido_Español_Final (1)-poster-00001.jpg');
          "
            muted
            playsinline
            ref="videoRef"
            data-wf-ignore="true"
            data-object-fit="cover"
        >
          <source
              src="https://uploads-ssl.webflow.com/62f4510e73201624361551ab/62f4967f04e067abd8d509cf_Iduam_Monitoreamos_Cada_latido_Español_Final (1)-transcode.mp4"
              data-wf-ignore="true"
          />
          <source
              src="https://uploads-ssl.webflow.com/62f4510e73201624361551ab/62f4967f04e067abd8d509cf_Iduam_Monitoreamos_Cada_latido_Español_Final (1)-transcode.webm"
              data-wf-ignore="true"
          />
        </video
        >
        <noscript
        ><img
            data-wf-bgvideo-fallback-img="true"
            src="documents/Iduam_Monitoreamos_Cada_latido_Español_Final-1.mp4"
            alt=""
        />
        </noscript>
        <div aria-live="polite">
          <button
              aria-controls="2c384245-6ee0-9d57-bb04-3436793b5cd2-video"
              type="button"
              data-w-bg-video-control="true"
              class="
              w-backgroundvideo-backgroundvideoplaypausebutton
              w-background-video--control
            "
              @click="togglePlay"
          >
            <span
            ><img
                src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg"
                alt="Pause video"/></span
            ><span hidden
          ><img
              src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg"
              alt="Play video"
          /></span>
          </button>
        </div>
        <div class="containerhtml w-containerhtml">
<!--           <div class="botones-marketplace">
            <a
                href="http://ventasboton.sosclick.cl/"
                class="buton-sosclickboton w-inline-block"
                target="_self"
            ><img
                src="images/logo-2.png"
                loading="lazy"
                alt=""
                class="image-16"
            />
              <div class="text-block-5">SOSCLICK Botón <br/>Marketplace</div>
            </a>
            <a
                href="https://ventasadultomayor.sosclick.cl/"
                class="buton-sosclickadultomayor w-inline-block"
                target="_self"
            ><img
                src="images/logo-2.png"
                loading="lazy"
                alt=""
                class="image-16"
            />
              <div class="text-block-5">SOSCLICK Adulto Mayor Marketplace</div>
            </a>
          </div> -->
          <h1 class="heading hero">IDUAM</h1>
          <p class="paragraph _2">Monitoreamos cada latido</p>

          <p class="paragraph">
            Es un asistente que monitorea parámetros de salud como la Presión
            Arterial, Ritmo Cardíaco, Saturación de Oxígeno en forma constante y
            proactiva.
          </p>
          <div class="d-flex flex-column flex-md-row" style="gap: 12px;">

          <a href="#" class="lightbox-link w-inline-block w-lightbox">
            <p class="paragraph-16 mb-0 text-center">Ver video</p>
            <script type="application/json" class="w-json">
              {
                "items": [
                  {
                    "url": "https://www.youtube.com/watch?v=RW_EclTXHxk",
                    "originalUrl": "https://www.youtube.com/watch?v=RW_EclTXHxk",
                    "width": 940,
                    "height": 528,
                    "thumbnailUrl": "https://i.ytimg.com/vi/RW_EclTXHxk/hqdefault.jpg",
                    "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRW_EclTXHxk%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRW_EclTXHxk&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRW_EclTXHxk%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                    "type": "video"
                  }
                ],
                "group": ""
              }
            </script>
            <!--script type="application/json" class="w-json">
            {
  "items": [
    {
      "url": "https://vimeo.com/270093260",
      "originalUrl": "https://vimeo.com/270093260",
      "width": 940,
      "height": 529,
      "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F270093260%3Fh%3D30c084bd7f%26app_id%3D122963&dntp=1&display_name=Vimeo&url=https%3A%2F%2Fvimeo.com%2F270093260&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=vimeo\" width=\"940\" height=\"529\" scrolling=\"no\" title=\"Vimeo embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
      "type": "video"
    }
  ],
  "group": ""
}
</script-->
          </a>
          <a @click="$router.push('/catalogo')" style="background: #0064F1" class="lightbox-link w-inline-block"><p class="btn mb-0">Comprar</p></a>
          </div>
        </div>
      </div>
    </div>
    <div class="about wf-section">
      <div class="div-block-14">
        <h1
            data-w-id="e3ece9d4-d326-1b40-b389-8da671b1d82c"
            style="opacity: 0"
            class="heading-7"
        >
          IDUAM
        </h1>
        <p
            data-w-id="a6d5365e-186b-5fe5-b790-8c2c5907075e"
            style="opacity: 0; text-align: justify"
            class="paragraph"
        >
          Es una plataforma que cuenta con una Aplicación Móvil y una Smartband
          o pulsera inteligente que te permite conocer en tiempo real tus
          parámetros de salud básicos tales como: Ritmo Cardíaco, Presión
          Arterial, Saturación de Oxígeno, Temperatura y Monitorización
          Electrocardiográfica con la interpretación de un algoritmo de
          Inteligencia Artificial (IA).
        </p>
        <div class="div-block">
          <a @click="$router.push('/catalogo')" class="button-primario w-button">Comprar </a>
          <a
              href="https://www.iduam.com?subject=Solicitar+información#contacto"
              class="button-secundario w-button"
              target="_self"
          >Solicitar información</a
          >
        </div>
      </div>
    </div>
    <div class="section-2 wf-section" id="comofunciona">
      <div class="containerhtml-3 w-containerhtml">
        <div class="div-block-3">
          <div class="div-block-4">
            <h1 class="heading-2-copy">como funciona</h1>
            <p class="paragraph-6">PARA UNA VIDA MÁS PLENA</p>
            <div class="div-block-5"></div>
          </div>
          <div class="div-block-2">
            <div
                data-w-id="dccfd1b4-1260-c6af-df9e-e48c86fd6522"
                style="opacity: 0"
                class="card"
            >
              <img
                  src="images/mediciones.png"
                  loading="lazy"
                  alt=""
                  class="image"
              />
              <h1 class="card-heading">MEDICIONES<br/>DE SALUD MANUALES</h1>
              <p class="paragraph-4">
                Mide de manera sencilla tus signos vitales con las Smartband
                Iduam, que en conjunto con nuestra Aplicación Móvil Iduam te
                permitirá medir tu Ritmo Cardíaco, Presión Arterial y realizar
                tu propia monitorización Electrocardiográfica.<br/><br/>
                Iduam arrojará tus resultados de manera inmediata, interpretados
                por un algoritmo de inteligencia artificial que te dirá si estás
                bien o tienes que consultar a tu médico.
              </p>
            </div>
            <div
                data-w-id="1d8fc2c3-9b3a-eca9-0403-9287b1352fb4"
                style="opacity: 0"
                class="card"
            >
              <img
                  src="images/alerta.png"
                  loading="lazy"
                  alt=""
                  class="image"
              />
              <h1 class="card-heading">NOTIFICACIONES<br/>ALERTAS</h1>
              <p class="paragraph-5">
                Para mayor precisión estadística en los niveles de Presión
                Arterial, Saturación de Oxígeno y Ritmo cardíaco, disponemos de
                la herramienta tipo Holter, la cual te permite realizar varias
                mediciones en un mismo día manteniendo en todo momento tu
                información sobre tus parámetros de salud, con la finalidad de
                poder prevenir una posible urgencia. <br/><br/>Además,
                mantiene tu historial de parámetros de salud actualizados.
              </p>
            </div>
            <div
                data-w-id="c020c1b5-3cde-4357-1248-334f4412e61c"
                style="opacity: 0"
                class="card"
            >
              <img src="images/24.png" loading="lazy" alt="" class="image"/>
              <h1 class="card-heading">MONITOREO<br/>(Próximamente)</h1>
              <p class="paragraph-3">
                Cuando contratas Iduam y sus servicios empleas la más
                especializada red de servicios de asistencia médica existente en
                el país, conformada por un equipo interdisciplinario de expertos
                en salud, telecomunicaciones y tecnología. <br/><br/>Gracias
                al esfuerzo de todos y cada uno de ellos, hemos conseguido crear
                un sistema que monitorea el estado de salud de nuestros
                pacientes de forma permanente y que brinda asistencia durante el
                día, de forma preventiva y reactiva.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="smartbands wf-section margin-bottom-20" id="Smartband">
      <div class="div-block-9">
        <div class="div-block-4">
          <h1 class="heading-2-copy light">Smartband</h1>
          <p class="paragraph-6">
            Elige la smartband que se ajuste más a tus necesidades
          </p>
          <div class="div-block-5"></div>
        </div>
      </div>
      <div class="div-block-8">
        <div
            data-w-id="355928cc-9094-f09e-f53a-acd237d4bd68"
            style="opacity: 0"
            class="card-smartband _2"
        >
          <div class="div-block-7">
            <img
                src="../../assets/images/T-68.png"
                loading="lazy"
                width="250"
                alt=""
                class="image-3"
            />
          </div>
          <h1 class="card-heading _2">Iduam T-68</h1>
          <p class="paragraph-7" style="text-align: justify">
            La Iduam T-68 te permite medir tu Ritmo Cardíaco, Presión Arterial,
            Saturación de Oxígeno, Temperatura.
          </p>
          <div class="div-block">
            <!-- <a href="#" class="button-primario w-button">Comprar </a> -->
            <a
                @click="addToCart(2)"
                target="_self"
                class="button-secundario w-button"
                style="border-radius: 100px"
            >Comprar</a
            >
          </div>
        </div>
        <div
            data-w-id="2a1bf26a-f9bb-8fe1-9e97-5c5d50c15e66"
            style="opacity: 1"
            class="card-smartband"
        >
<!--           <div class="div-block-10">
            <h1 class="heading-4">Nuevo modelo</h1>
          </div> -->
          <div class="div-block-7">
            <img
                src="images/14.png"
                loading="lazy"
                width="200"
                srcset="images/14.png 500w, images/14.png 796w"
                sizes="(max-width: 479px) 300px, 306px"
                alt=""
            />
          </div>
          <h1 class="card-heading _2">Iduam E-600</h1>
          <p class="paragraph-8" style="text-align: justify">
            La Iduam E-600 te permite medir tu Ritmo Cardíaco, Presión
                Arterial, Saturación de Oxígeno, Temperatura y podrás realizarte
                un Electrocardiograma desde nuestra Aplicación Móvil o lectura
                en la propia Smartband.La Iduam 600 esta equipada con el Ti 1292
                IC para la función de ECG, basado en este IC, los resultados de
                las pruebas de presión arterial serán más precisos,
                especialmente para las personas hipertensas.
          </p>
          <div class="div-block">
            <!-- <a href="#" class="button-primario w-button">Comprar </a> -->
            <a
                @click="addToCart(1)"
                target="_self"
                class="button-secundario w-button"
                style="border-radius: 100px"
            >Comprar</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="section-3 wf-section">
      <div class="div-block-12">
        <div
            data-w-id="273afb48-cd7c-f729-949e-de5902ebbf92"
            style="opacity: 0"
            class="div-block-4"
        >
          <h1
              data-w-id="273afb48-cd7c-f729-949e-de5902ebbf93"
              style="opacity: 0"
              class="heading-2-copy light"
          >
            La aplicación
          </h1>
          <p class="paragraph-6">Conoce todas las cualidades de nuestra App</p>
          <div class="div-block-5"></div>
          <p>
            Con la Aplicación Móvil Iduam, en conjunto con la smartband Iduam
            T-68, podrás monitorear en todo momento tus parámetros
            de salud, tales como: Ritmo Cardíaco, Presión Arterial, Saturación
            de Oxígeno y Monitorización Electrocadiográfico. A través del
            aplicativo podrás tener un mayor conocimiento de tus parámetros de
            salud al instante.
          </p>
        </div>
        <div class="w-layout-grid grid">
          <div
              id="w-node-dfde6579-21b2-6b60-441e-70cf4e720073-721551ac"
              data-w-id="dfde6579-21b2-6b60-441e-70cf4e720073"
              style="opacity: 0"
              class="div-block-13"
          >
            <h1 class="app-h1">Monitorización de Valores</h1>
            <div class="div-block-5"></div>
            <p class="paragraph-13" style="text-align: justify">
              Conoce tus parámetros de Salud de manera instantánea. La
              Aplicación Móvil contiene un abanico de funcionalidades para que
              estés siempre monitoreando tus parámetros de Salud.
            </p>
          </div>
<!--           <div
              id="w-node-faa40b06-1e91-c5f9-f8a8-cf2a2428dd65-721551ac"
              data-w-id="faa40b06-1e91-c5f9-f8a8-cf2a2428dd65"
              style="opacity: 0"
          >
            <h1 class="app-h1">Monitoreo de ECG</h1>
            <div class="div-block-5"></div>
            <p class="paragraph-9" style="text-align: justify">
              Podrás generar una monitorización Electrocardiográfica o ECG, el
              cual podrá ser interpretado a través de un algoritmo de
              Inteligencia Artificial (IA). Esta monitorización es solo de
              carácter informativo ya que IDUAM no puede establecer o determinar
              anomalías cardíacas las que solo pueden ser diagnosticadas por tu
              Médico. Si te aparece una interpretación de anomalía
              repetidamente, realiza unas dos veces más el ECG por cualquier
              error de interpretación. Ahora si las anomalías se mantienen
              consulta con tu Medico a la brevedad.
            </p>
          </div> -->
          <!--div id="w-node-a2b36bf9-1d0d-2642-c3ab-afa59943d25b-721551ac" data-w-id="a2b36bf9-1d0d-2642-c3ab-afa59943d25b" style="opacity:0">
          <h1 class="app-h1">Control a Distancia </h1>
          <div class="div-block-5"></div>
          <p class="paragraph-10" style="text-align:justify;">Maneja el perfil de tu familiar desde la aplicación móvil desde cualquier lugar que te encuentres, pudiendo asignarle, mediciones, monitoreo y recordatorios.</p>
        </div>
        <div id="w-node-_41b6366b-1499-27f9-4b0c-43eb601829bd-721551ac" data-w-id="41b6366b-1499-27f9-4b0c-43eb601829bd" style="opacity:0">
          <h1 class="app-h1">Recordatorios</h1>
          <div class="div-block-5"></div>
          <p class="paragraph-12" style="text-align:justify;">Crea recordatorios de toma de medicamentos para tu familiar, así si a éste se le olvida tomárselo, te enviarán una notificación para que le recuerdes a tu Tata.</p>
        </div-->
          <div
              id="w-node-bd9a264b-556b-fbe6-7fb3-6d33a72673cd-721551ac"
              data-w-id="bd9a264b-556b-fbe6-7fb3-6d33a72673cd"
              style="opacity: 0"
          >
            <h1 class="app-h1">Historial</h1>
            <div class="div-block-5"></div>
            <p class="paragraph-11" style="text-align: justify">
              Revisa en cualquier momento desde tu celular o mediante nuestro
              portal web, el historial de tus mediciones médicas. A tu historial
              de parámetros médicos podrás ingresar incorporando las mismas
              claves que utilizas en la Aplicación Móvil IDUAM. Estos parámetros
              no solo te mostraran tu historial, sino que estos támbien puedes
              compartirlos con tu médico el cual podrá revisarlos e
              interpretarlos.
            </p>
          </div>
          <img
              class="image-2"
              src="images/abuela.png"
              alt=""
              style="opacity: 0"
              sizes="100vw"
              data-w-id="0a78d568-3533-f466-d4fd-253619dce502"
              id="w-node-_0a78d568-3533-f466-d4fd-253619dce502-721551ac"
              loading="lazy"
              srcset="
              images/abuela.png  500w,
              images/abuela.png  800w,
              images/abuela.png 1080w,
              images/abuela.png 1094w
            "
          />
          <div
              id="w-node-_32060887-9cdc-2d7a-61ec-e4cc83173c01-721551ac"
              data-w-id="32060887-9cdc-2d7a-61ec-e4cc83173c01"
              style="opacity: 0"
          >
            <br/><br/><br/><br/><br/><br/>
            <h1 class="app-h1">Red de Emergencia de Salud</h1>
            <div class="div-block-5"></div>
            <p class="paragraph-14" style="text-align: justify">
              Genera tu red de emergencia de Salud incorporando hasta 5 personas
              ya sea familiares, vecinos o amigos. Toda persona que conforme tu
              red, podrá asistirte ante cualquier emergencia que tengas por
              video conferencia, así estos podrán determinar la ayuda que
              necesitas de mejor manera. La plataforma IDUAM en algún momento
              incorporará la atención médica a distancia, la cual una vez que se
              tenga en producción te será informada para tu posterior
              contratación siempre y cuando así tu lo estimes.
            </p>
          </div>
        </div>
      </div>
    </div>
<!--     <div class="pricing-comparison wf-section" id="planes">
      <div class="containerhtml-2">
        <div class="pricing-wrapper">
          <div
              class="luna-pricebox wow fadeInRight"
              data-wow-delay="0.25s"
              style="
                  visibility: visible;
                  animation-delay: 0.25s;
                  animation-name: fadeInRight;
                "
          >
            <div class="price-top">
              <h4>Plan Básico</h4>

              <p class="price">
                <span class="currency">$</span>
                <span class="number">{{basicPlanPrice | formatNumber}} IVA Incl.</span>
                <span class="period">Anual</span>
              </p>
            </div>

            <div class="price-bottom">
              <ul>
                <li>
                  <i class="fa fa-check"></i>Aplicación Móvil y Smartband
                </li>
                <li>
                  <i class="fa fa-check"></i>Realizar mediciones manuales y
                  Automáticas tipo Holter
                </li>
                <li>
                  <i class="fa fa-check"></i>ECG o Electrocardioagrama con
                  interpretación de Inteligencia Artificial (IA)
                </li>
                <li>
                  <i class="fa fa-check"></i>Historial de mediciones en la
                  aplicación móvil y en el Portal Web
                </li>
                <li class="last">
                  <i class="fa fa-check"></i>Red de Seguridad y Emergencia
                  Personal (5 usuarios)
                </li>
              </ul>

              <a href="https://ventas.iduam.com/Checkout?plan=1" class="btn-price"
              >Comprar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--          <div-->
    <!--            id="w-node-caa123b3-4e5c-e327-9ba2-dac92e42ad75-721551ac"-->
    <!--            data-w-id="caa123b3-4e5c-e327-9ba2-dac92e42ad75"-->
    <!--            style="opacity: 0"-->
    <!--            class="pricing-card"-->
    <!--          >-->
    <!--            <img-->
    <!--              src="images/24.png"-->
    <!--              loading="lazy"-->
    <!--              alt=""-->
    <!--              class="pricing-image"-->
    <!--            />-->
    <!--            <h2 class="pricing-title">Plan Premium</h2>-->
    <!--            <div class="pricing-subtitle">Valor Bruto</div>-->
    <!--            <div class="pricing-price">$349.990/Año</div>-->
    <!--            <div class="paragraph-regular margin-bottom-20"></div>-->
    <!--            <a-->
    <!--              href="https://www.click2call.cl/?ShareLink=5"-->
    <!--              target="_self"-->
    <!--              class="button-secundario _2 w-button"-->
    <!--              >Contactar un vendedor</a-->
    <!--            >-->
    <!--            <br />-->
    <!--            <a href="#" class="button-secundario _2 w-button"-->
    <!--              >Próximamente</a-->
    <!--            >-->
    <!--            <div class="pricing-divider"></div>-->
    <!--            <div class="pricing-tag">Próximamente</div>-->
    <!--            <ul role="list" class="pricing-feature-list _3 w-list-unstyled">-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">Aplicación Móvil y Smartband</div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">-->
    <!--                  Realizar mediciones manuales y Automáticas tipo Holter-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">-->
    <!--                  ECG o Electrocardioagrama con interpretación de Inteligencia-->
    <!--                  Artificial (IA)-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">-->
    <!--                  Historial de mediciones en la Aplicación Móvil y en el Portal-->
    <!--                  Web-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">-->
    <!--                  Red de Seguridad y Emergencia Personal (5 usuarios)-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">-->
    <!--                  Monitoreo online Anual en horario diurno oficina-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <div class="pricing-feature">-->
    <!--                  Consulta Médica online (Una por mes)-->
    <!--                </div>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->


    <section class="testimonial-slider-small wf-section">
      <div class="containerhtml-2">
        <h2 class="centered-heading">Testimonios</h2>
        <p class="centered-subheading">
          Algunos de nuestro cliente te cuentan su experiencia con Iduam
        </p>
        <div
            data-delay="4000"
            data-animation="slide"
            class="testimonial-slider w-slider"
            data-autoplay="false"
            data-easing="ease"
            data-hide-arrows="true"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="true"
        >
          <div class="w-slider-mask">
            <div class="testimonial-slide-wrapper w-slide">
              <div class="testimonial-card">
                <p>
                  Ahora puedo cuidar a mi mamá desde la distancia y me siento
                  mucho más tranquila
                </p>
                <div class="testimonial-info">
                  <img
                      src="images/PatriciaDuffy_headshot.webp"
                      loading="lazy"
                      alt=""
                      class="testimonial-image"
                  />
                  <div>
                    <h3 class="testimonial-author">Marta Lagos</h3>
                    <div class="tagline">Hija</div>
                  </div>
                  <div class="testimonial-icon-wrapper">
                    <img
                        src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a6c9412aae6_double-quotes-l.svg"
                        loading="lazy"
                        alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="testimonial-slide-wrapper w-slide">
              <div class="testimonial-card">
                <p>
                  Todos los días puedo saber el estado de salud de mis papás con
                  tan solo presionar un click en mi celular
                </p>
                <div class="testimonial-info">
                  <img
                      src="images/v3_0032439.jpeg"
                      loading="lazy"
                      alt=""
                      class="testimonial-image"
                  />
                  <div>
                    <h3 class="testimonial-author">Diego Fuenzalida</h3>
                    <div class="tagline">Hijo</div>
                  </div>
                  <div class="testimonial-icon-wrapper">
                    <img
                        src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a6c9412aae6_double-quotes-l.svg"
                        loading="lazy"
                        alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="testimonial-slide-wrapper w-slide">
              <div class="testimonial-card">
                <p>
                  Ahora puedo cuidar a mi hermano y acompañarlo todos los días
                  gracias a que mi sobrino compró este servicio.
                </p>
                <div class="testimonial-info">
                  <img
                      src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg"
                      loading="lazy"
                      alt=""
                      class="testimonial-image"
                  />
                  <div>
                    <h3 class="testimonial-author">Luis Mondaca</h3>
                    <div class="tagline">Hermano</div>
                  </div>
                  <div class="testimonial-icon-wrapper">
                    <img
                        src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a6c9412aae6_double-quotes-l.svg"
                        loading="lazy"
                        alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="testimonial-slide-wrapper w-slide">
              <div class="testimonial-card">
                <p>
                  Estoy feliz por que puedo controlar la salud de mi madre a
                  diario.
                </p>
                <div class="testimonial-info">
                  <img
                      src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg"
                      loading="lazy"
                      alt=""
                      class="testimonial-image"
                  />
                  <div>
                    <h3 class="testimonial-author">Patricia Araya</h3>
                    <div class="tagline">Hija</div>
                  </div>
                  <div class="testimonial-icon-wrapper">
                    <img
                        src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a6c9412aae6_double-quotes-l.svg"
                        loading="lazy"
                        alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-slider-left w-slider-arrow-left">
            <div class="arrow-wrapper">
              <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a0e4912aadb_Chevron%20right-1.svg"
                  loading="lazy"
                  alt=""
                  class="slider-arrow-embed"
              />
            </div>
          </div>
          <div class="testimonial-slider-right w-slider-arrow-right">
            <div class="arrow-wrapper">
              <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a7ce212aacc_Chevron%20right.svg"
                  loading="lazy"
                  alt=""
                  class="slider-arrow-embed"
              />
            </div>
          </div>
          <div class="testimonial-slide-nav w-slider-nav w-round"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
  getFinalPaymenInfo
} from "../../helpers/api/planes";

export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    basicPlanPrice: ''
  }),
  mounted() {
    if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
      this.getOrderStatus(this.$route.query.token);
    }
    setTimeout(() => {
      this.togglePlay();
    }, 1000);


    getFinalPaymenInfo({product_id: 1, qty: 1,}).then(res => {
      this.basicPlanPrice = res.final_price
    })
  },
  methods: {
    togglePlay() {
      this.playing = !this.playing;
      if (this.playing) {
        this.$refs.videoRef.play();
      } else {
        this.$refs.videoRef.pause();
      }
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
            planPayment.flowData &&
            planPayment.flowData.url &&
            planPayment.flowData.token
        ) {
          const payUrl =
              planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({order_token});
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.media
              : "",
          fecha: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.date
              : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: {open: true, data},
        });
      }
    },
    addToCart(product_id) {
        const productIndex = this.$store.state.products.findIndex(product => product.product_id === product_id);
            if (productIndex  === -1) {
                this.$store.commit('setCart', [...this.$store.state.products, {product_id, qty: 1}])
            } else {
              const arr = this.$store.state.products;
              arr[productIndex].qty += 1;

              this.$store.commit('setCart', arr);
            }
            setTimeout(() => {
                //loader
                this.$router.push({ name: 'Checkout2' })
            }, .5);
        },
  },
  filters: {
    formatNumber: function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/iduam.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.btn {
    padding: 10px 100px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 20px;
    line-height: 35px;
    font-weight: 300;
    text-decoration: none;
}
</style>
