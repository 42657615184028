var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-no-shadow wf-section"},[_c('div',{staticClass:"navbar-no-shadow-containerhtml w-nav",attrs:{"data-animation":"default","data-collapse":"medium","data-duration":"400","data-easing":"ease","data-easing2":"ease","role":"banner"}},[_c('div',{staticClass:"containerhtml-regular"},[_c('div',{staticClass:"navbar-wrapper"},[_vm._m(0),_c('nav',{staticClass:"nav-menu-wrapper w-nav-menu",attrs:{"role":"navigation"}},[_c('ul',{staticClass:"nav-menu w-list-unstyled",attrs:{"role":"list"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"mobile-margin-top-10"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://www.iduam.com?subject=Contactar+a+un+representante#contacto","target":"_self"}},[_vm._v("Contacto")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"mobile-margin-top-10"},[_vm._m(6)])])]),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand w-nav-brand",attrs:{"href":"/"}},[_c('img',{attrs:{"src":"images/logo.svg","loading":"lazy","width":"140","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":"http://www.iduam.com/","target":"_self"}},[_vm._v("Web Iduam")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":"https://ventasiduam.web.app/#comofunciona"}},[_vm._v("Como Funciona")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":"https://ventasiduam.web.app/#Smartband"}},[_vm._v("Smartband")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":"https://www.youtube.com/channel/UCBnz1KlUdfLt1misGsGk_lQ","target":"_self"}},[_vm._v("VideoTutorial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":"/preguntas_frecuentes"}},[_vm._v("Preguntas Frecuentes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-button-wrapper"},[_c('a',{staticClass:"button-primary w-button",attrs:{"href":"https://www.iduam.com?subject=Contactar+a+un+representante#contacto","target":"_self"}},[_vm._v("Contactar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-button w-nav-button"},[_c('div',{staticClass:"w-icon-nav-menu"})])
}]

export { render, staticRenderFns }