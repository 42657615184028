<template>
   <section class="footer-dark wf-section">
      <div class="containerhtml-2">
        <div class="footer-wrapper">
          <div style="display: flex;flex-wrap:wrap; gap: 20px; max-width:150px;">
          <a href="https://ventasiduam.web.app/" style="z-index: 123; position:relative;" class="footer-brand w-inline-block"
            ><img src="images/logo.svg" loading="lazy" width="154" alt=""
          /></a>
          <a href="https://www.naturalphone.cl/" style="z-index: 123; position:relative;" class="footer-brand w-inline-block"
            ><img  :src="require('/src/assets/images/naturalphone.png')" loading="lazy" width="154" alt=""
          /></a>
          </div>
          <ul role="list" class="nav-menu-footer w-list-unstyled">
            <li>
              <a style="z-index: 123; position:relative;" href="https://www.iduam.com/" class="nav-link" target="_self"
                >Web Iduam</a
              >
            </li>
            <li><a style="z-index: 123; position:relative;" href="https://ventasiduam.web.app/#comofunciona" class="nav-link">Como Funciona</a></li>
<!--             <li><a href="/#Smartband" class="nav-link">Smartband</a></li> -->
            <!-- <li><a href="#" class="nav-link">Monitoreo</a></li> -->
            <li>
              <a
                style="z-index: 123; position:relative;"
                href="https://www.youtube.com/channel/UCBnz1KlUdfLt1misGsGk_lQ"
                class="nav-link"
                target="_self"
                >VideoTutorial</a
              >
            </li>
<!--             <li>
              <a
                href="http://ventasadultomayor.sosclick.cl/"
                class="nav-link"
                target="_self"
                >Adulto Mayor</a
              >
            </li>
            <li>
              <a
                href="http://ventasboton.sosclick.cl/"
                class="nav-link"
                target="_self"
                >Botón Adulto Mayor</a
              >
            </li>
            <li>
              <a
                href="/preguntas_frecuentes"
                class="nav-link"
                >Preguntas Frecuentes</a
              >
            </li> -->
            <li class="mobile-margin-top-10">
              <div class="nav-button-wrapper-footer">
                <a
                  href="https://www.iduam.com?subject=Contactar+a+un+representante#contacto"
                  class="button-primary w-button"
                  >Contactar</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-divider"></div>
      <div class="footer-copyright-center">Copyright © 2022 Naturalphone</div>
    </section>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>